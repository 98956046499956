<template>
  <div>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Operações">
        <Operacoes />
      </CTab>
      <CTab title="Cobranças">
        <Cobrancas />
      </CTab>
      <CTab title="Planos de Pagamento">
        <PlanosPagamento />
      </CTab>
      <CTab title="Cobrança x Grupo">
        <GruposCobranca />
      </CTab>
      <CTab title="Cobrança x Plano">
        <PlanosCobranca />
      </CTab>
      <CTab title="Operação x Cobrança">
        <CobrancaOperacao />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import PlanosPagamento from './Condicao/PlanosPagamento';
import Cobrancas from './Condicao/Cobrancas';
import Operacoes from './Condicao/Operacoes';
import GruposCobranca from "./Condicao/GruposCobranca";
import PlanosCobranca from "./Condicao/PlanosCobranca";
import CobrancaOperacao from "./Condicao/CobrancaOperacao";

import { loadDescs } from '@/helpers/utils';
export default {
  name: 'condicao-pagamento',
  components: {
    PlanosPagamento,
    Cobrancas,
    Operacoes,
    GruposCobranca,
    PlanosCobranca,
    CobrancaOperacao
  },

  data() {
    return {
      descSingular: '',
      descPlural: '',
    };
  },

  beforeMount() {
    const { descSingular, descPlural } = loadDescs('ADMIN_CONDICAO_PAGAMENTO');
    this.descSingular = descSingular;
    this.descPlural = descPlural;
  },
};
</script>
