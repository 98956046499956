<!-- Use este arquivo como base para novos módulos de CRUD, -->
<!-- apenas copiando ele e colando na pasta respectiva -->

<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    :checkAlerts="true"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../../components/crud/CrudBase";
import {
  schemaBoolean,
  schemaSqlEditor,
  schemaTextArea,
  schemaTextField
} from "@/helpers/form";
import {yesNoRepresentation} from "@/helpers/transformers";
export default {
  name: "crud-template",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/condicoes/cobranca-plano",
      columns: [
        {
          label: "ID",
          field: "id",
          sortable: false
        },
        {
          label: "Cobrança",
          field: "cobranca",
          sortable: false,
          representedAs : (row) => !!(row?.cobranca)
              ? `${row.cobranca_id} - ${row.cobranca.descricao}`
              : row.cobranca_id
        },
        {
          label: "Plano de Pagamento",
          field: "plano_pagamento",
          sortable: false,
          representedAs : (row) => !!(row?.plano_pagamento)
              ? `${row.planopagamento_id} - ${row.plano_pagamento.descricao}`
              : row.planopagamento_id
        },
        {
          label: "Ativo",
          field: "ativo",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.ativo)
        }
      ],
    };
  },
};
</script>
