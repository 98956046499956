<template>
  <crud-base
    :url="url"
    :columns="columns"
    :onDelete="() => {}"
    :onUpdate="() => {}"
    :onStore="() => {}"
    :enableSearch="true"
    :formInputs="formInputs"
    :check-alerts="true"
    primaryKey="id"
  />
</template>

<script>
import CrudBase from "../../../components/crud/CrudBase";
import {
  schemaBooleanSelect,
  schemaMoney,
  schemaNumber,
  schemaPercent, schemaSelect,
  schemaTextArea,
  schemaTextField
} from "@/helpers/form";
import {yesNoRepresentation} from "@/helpers/transformers";

export default {
  name: "cobrancas",
  components: {
    CrudBase,
  },
  data() {
    return {
      url: "/admin/condicoes/cobrancas",
      columns: [
        {
          label: "Id",
          field: "id",
          sortable: false
        },
        {
          label: "Descrição",
          field: "descricao",
          sortable: false
        },
        {
          label: "Visível B2B",
          field: "acessob2b",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessob2b)
        },
        {
          label: "Visível CALL",
          field: "acessocallcenter",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessocallcenter)
        },
        {
          label: "Visíel FV",
          field: "acessofv",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.acessofv)
        },
        {
          label: "Ativo",
          field: "ativo",
          sortable: false,
          representedAs: (row) => yesNoRepresentation(row.ativo)
        },
        {
          label: "Ordem",
          field: "acessofv",
          sortable: false,
        }
      ],
      formInputs: {
        id: schemaTextField('Cód.', true, 2),
        ativo: schemaBooleanSelect('Ativo', true, 2, 1, 1),
        acessob2b: schemaBooleanSelect('Visível B2B?', true, 2, 1, 1),
        acessocallcenter: schemaBooleanSelect('Visível Call?', true, 2, 1, 1),
        acessofv: schemaBooleanSelect('Visível FV?', true, 2, 1, 1),
        prioridade: schemaNumber('Ordem exibição', true, 2),

        descricao: schemaTextField('Descrição', true, 6),
        descricaob2b: schemaTextField('Descrição (b2b)', false, 6),

        tipo: schemaSelect('Tipo de Cobrança', false, 3, {'AV' : 'À vista', 'AP' : 'A prazo'}, 'AV', []),
        valorminimo: schemaMoney('Valor mínimo parcela', true, 3, 0),
        cartao: schemaBooleanSelect('É cartão?', true, 3, 1, 0),
        taxacartao: schemaPercent('Taxa cartão (%)', false, 3, 0),

        inclusao: schemaBooleanSelect('Usa para inclusões?', true, 3, 1),
        link: schemaBooleanSelect('Usa link de pagamento?', true, 3, 1, 0),
        pix: schemaBooleanSelect('É pix?', true, 3, 1, 0),
        usa_consumidor_final: schemaBooleanSelect('Usa no modo cliente final?', true, 3, 1, 0),

        taxafixa: schemaMoney('Despesa acessória (R$)', false, 6),
        descricaotaxafixa: schemaTextField('Descrição da despesa', false, 6),

        observacao: schemaTextArea('Descrição ao cliente a ser exibido no checkout', false, 12),
      },
    };
  },
};
</script>
